<!-- eslint-disable  -->
<template>
  <div>
    <!-- <b-row class="content-header">
      <b-col class="content-header-left" md="12" cols="12">
      </b-col>
    </b-row> -->
    <b-row v-if="fileExists">
      <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="md"
          class="mr-50"
          @click="saveData(true)"
        >
          Importar
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            size="md"
            style="margin-right: 2px"
            @click="openModal()"
          >
            Previsualización
          </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          size="md"
          style="margin-right: 2px"
          @click="saveData(false)"
        >
          Prueba
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          size="md"
          style="margin-right: 2px"
          @click="cambioTipoSchema"
        >
          Cancelar
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="flex" id="app">
          <b-card-text
            v-if="!fileExists"
            class="flex w-full h-screen items-center justify-center"
          >
            <b-row class="mb-1">
              <b-col md="10" sm="12" class="mt-1 mt-md-0">
                <div
                  class="d-flex h-100 align-items-center justify-content-center justify-content-md-start"
                >
                  <!-- <h5 class="mr-2 mb-0">Ayuda</h5> -->
                  <b-button
                    class="mb-0"
                    variant="success"
                    @click="exportExcel(project_id)"
                  >
                    <div class="d-flex">
                      <feather-icon icon="DownloadCloudIcon" size="17" />
                      <span class="ml-25">Descargar formato</span>
                    </div>
                  </b-button>
                </div>
              </b-col>
              <b-col md="2" sm="12">
                <div class="d-flex align-items-center w-100">
                  <b-button variant="danger" class="w-100" @click="back">
                    Volver
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <div
              @click="$refs.file.$el.click()"
              style="height: 400px !important"
              class="p-12 bg-gray-100 border border-gray-300 text-center d-flex justify-content-center align-items-center"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <b-form-file
                :hidden="true"
                plain
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChangeFile"
                ref="file"
                accept=".xls,.xlsx,.csv"
              />
              <label for="assetsFieldHandle" class="block cursor-pointer">
                <div class="text-center mt-2">
                  <b-img
                    ref="previewEl"
                    rounded
                    :src="require('@/assets/images/icons/file.jpg')"
                    height="80"
                  />
                  <h4>Upload an Excel file to import</h4>
                  <span class="mb-2">Click o arrastre el archivo</span><br />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="md"
                    class="mb-75 mr-75 mt-2"
                  >
                    Subir Archivo
                  </b-button>
                </div>
              </label>
            </div>
          </b-card-text>
          <b-card-text v-else>
            <b-row>
              <b-col md="2">
                <div class="sidebar-left">
                  <div class="sidebar mr-25">
                    <div class="sidebar-content">
                      <h5 class="filter-title mt-0 mb-2">
                        Información del archivo
                      </h5>
                      <div>
                        <b-link class="mb-1">
                          <div class="d-flex mb-25">
                            <feather-icon icon="FileTextIcon" size="17" />
                            <span class="ml-25">{{
                              configurationFile.name
                            }}</span>
                          </div>
                        </b-link>
                        <b-form-checkbox
                          v-model="configurationFile.firstRowHeader"
                          @change="cambioLogicHeader"
                        >
                          Primera fila es cabecera?
                        </b-form-checkbox>
                      </div>
                      <!-- <h5 class="mt-2 mb-2">Ayuda</h5>
                      <div>
                        <b-link
                          class="mb-1"
                          download="productos.xlsx"
                          href="/assets/files/productos.xlsx"
                        >
                          <div class="d-flex mb-25">
                            <feather-icon icon="DownloadCloudIcon" size="17" />
                            <span class="ml-25">Descargar formato</span>
                          </div>
                        </b-link>
                      </div> -->
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="10">
                <b-list-group class="ml-3">
                  <b-list-group-item
                    v-for="(item, index) of schema"
                    :key="item.prop"
                    class="flex-column align-items-start"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">
                        {{ item.title }}
                      </h5>
                      <small class="text-secondary"
                        >ej: {{ item.example }}</small
                      >
                    </div>
                    <b-card-text class="mb-1">
                      <b-row>
                        <b-col md="6">
                          <!-- <b-form-select
                            v-model="schema[index].id"
                            text-field="text"
                            value-field="id"
                            :options="headers"
                          /> -->
                        </b-col>
                        <b-col md="6">
                          <b-alert
                            variant="danger"
                            :show="item.errors.length > 0"
                          >
                            <b-card-text class="ml-1 mb-1 mr-1">
                              <span v-if="item.validateCall"
                                >No se encontraron registros con los datos
                                ingresados como {{ item.title }}.</span
                              >
                              <span v-else
                                >Se encontraron los siguientes errores:
                              </span>
                            </b-card-text>
                            <ul>
                              <li
                                v-for="(error, i) of item.seeAllErrors
                                  ? item.errors
                                  : item.errors.slice(0, lengthMax)"
                                :key="i"
                              >
                                {{ error }}
                              </li>
                            </ul>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="flat-primary"
                              v-if="item.errors.length > 3"
                              @click="seeAllErrors(index)"
                            >
                              Ver todos
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="flat-primary"
                              v-if="item.errors.length > 3"
                              @click="seeLessErrors(index)"
                            >
                              Ver Menos
                            </b-button>
                          </b-alert>
                          <b-alert
                            variant="danger"
                            :show="item.errors.length > 0 && item.validateCall"
                          >
                            <b-card-text class="ml-1 mb-1 mr-1">
                              <span
                                >Cuando no se encuentran valores enlazados:
                              </span>
                              <!-- <b-form-select
                                v-model="item.actionMatch"
                                :options="actionsMatch"
                                size="md"
                              /> -->
                            </b-card-text>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </b-card-text>
                    <small class="text-secondary"></small>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-modal size="xl" v-model="modalOpen" @close="closeModal" hide-footer>
        <template v-slot:default>
          <prevExcel :datos="dataExcel" />

        </template>
      </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import readXlsxFile from "read-excel-file";
// import jsonData from "@/generalData.json";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import RestrictionsService from "@/services/RestrictionsService";
import prevExcel from './prevExcel.vue'

const API_URL = process.env.APIURL;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
export default {
  components: {
    vSelect,
    prevExcel,

  },
  data() {
    return {
      apiurl: API_URL,
      modalOpen: false,
      dataExcel: [],
      showLoading: false,
      optionsCuenta: [],
      project_id: JSON.parse(localStorage.getItem("project_id")),
      userData: JSON.parse(localStorage.getItem("userData")),
      selected: "Venta",
      contentFile: null,
      headers: [],
      schema: [],
      fileExists: false,
      configurationFile: {
        name: "",
        extension: "",
        size: 0,
        firstRowHeader: true,
      },
      // actionsMatch: jsonData.import.actionsMatch,
      lengthMax: 3,
      file: null,
      firstRow: null,
    };
  },
  async mounted() {
    const resp = await this.$store.dispatch("back/GET_GENERAL");
    console.log("resp", resp);
    this.schema = resp.provisionalSchema;
  },
  directives: {
    Ripple,
  },
  methods: {
    async exportExcel(id) {
      if (id != null) {
        this.showLoading = true;
        console.log("url", this.apiurl);
        var fileLink = document.createElement("a");
        fileLink.href =
          this.apiurl + "restriction/downloadFormat/Provisional/" + id;
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        this.showLoading = false;
      }
    },
    back() {
      this.$router.push("/provisionales");
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async cambioTipoSchema() {
      this.cancelarImportacion();
      const resp = await this.$store.dispatch("back/GET_GENERAL");
      this.schema = resp.provisionalSchema;
    },
    async saveData(save_data) {
      this.showLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      var schemaSend = {
        save: save_data,
        schema: this.schema,
        project_id: this.project_id,
        user_id: this.userData.id,
      };
      console.log("schemaSend", schemaSend);
      formData.append("schema", JSON.stringify(schemaSend));
      const provImport = await RestrictionsService.saveImportProvisional(
        formData,
        this.$store
      );
      console.log("provImport", provImport);
      if (provImport.status) {
        console.log("provImport", provImport);
        var findErr = false;
        for (let i = 0; i < provImport.data.schema.length; i++) {
          this.schema[i].errors = provImport.data.schema[i].errors;
          if (provImport.data.schema[i].errors.length > 0) {
            findErr = true;
          }
        }
        console.log("findErr", findErr);
        if (save_data && !findErr) {
          this.cancelarImportacion();
          this.$swal({
            icon: "success",
            title: "Importado!",
            text: "Las provisionales ha sido importadas con exito.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (!save_data && findErr) {
          console.log("provImport", provImport);
          this.$swal({
            icon: "warning",
            title: "Errores",
            text: "Se presentaron los siguientes errores.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          this.$swal({
            icon: "success",
            title: "Éxito",
            text: "La prueba se realizó con éxito.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al importar las provisionales.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
      this.showLoading = false;
    },
    showMessage(mensaje, icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        html: mensaje,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    seeAllErrors(index) {
      this.$set(this.schema[index], 'seeAllErrors', true);
    },
    seeLessErrors(index) {
      this.schema[index].seeAllErrors = false;
    },
    cambioLogicHeader() {
      this.headers = [];
      if (this.configurationFile.firstRowHeader) {
        for (let i = 0; i < this.firstRow.length; i++) {
          this.headers.push({ id: i, text: this.firstRow[i] });
        }
      } else {
        for (let i = 0; i < this.firstRow.length; i++) {
          this.headers.push({ id: i, text: "Columna " + (i + 1) });
        }
      }
    },
    async cancelarImportacion() {
      this.fileExists = false;
      this.headers = [];
      this.file = null;
      this.firstRow = null;
      this.configurationFile = {
        name: "",
        extension: "",
        size: 0,
        firstRowHeader: true,
      };
    },
    processFile(xlsxfile) {
      this.configurationFile.name = xlsxfile.name;
      this.configurationFile.size = xlsxfile.size;

      readXlsxFile(xlsxfile, { sheet: 1 }).then((rows) => {
        console.log("HOLA",rows)
        console.log("TIPO DE FECHA", typeof rows[1][8])
        this.dataExcel = rows

        if (rows.length > 0) {
          this.fileExists = true;
          this.headers = [];
          this.firstRow = rows[0];
          if (this.configurationFile.firstRowHeader) {
            for (let i = 0; i < rows[0].length; i++) {
              this.headers.push({ id: i, text: rows[0][i] });
            }
          }
        }
      });
    },
    onChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        this.processFile(this.file);
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {},
    drop(event) {
      event.preventDefault();
      this.file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
      if (this.file) {
        this.processFile(this.file);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mt-05 {
  margin-top: 0.5rem;
}
</style>

<template>
  <div>
    <b-table :items="info" :fields="fields" responsive no-header striped sticky-header="95vh">
      <!-- <template #cell(1)="data">
        <div class="description-class">
          <span>{{ data.value }}</span>
        </div>
      </template>
      <template v-slot:cell(4)="data">
        {{ formatearFecha(data.value, data.index) }}
      </template>
      <template v-slot:cell(5)="data">
        {{ formatearFecha(data.value, data.index) }}
      </template> -->
      <template #cell(Fecha_Identificada)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Identificada) }}</span>
        </div>
      </template>
      <template #cell(Fecha_Requerida)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Requerida) }}</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';

export default {
  props: {
    datos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      firstRow: [],
      info:[],
      fields: [
        { key: 'ID_Restriccion', label: 'ID_Restriccion', sortable: false },
        { key: 'Descripcion', label: 'Descripcion', sortable: false },
        { key: 'Prioridad', label: 'prioridad' },
        { key: 'Categoria', label: 'categoria' },
        { key: 'Fecha_Identificada', label: 'fecha_identificada' },
        { key: 'Fecha_Requerida', label: 'fecha_requerida' },
        { key: 'Estado', label: 'Estado' },
        { key: 'Tipo', label: 'Tipo' },
      ],
    };
  },
  created() {
    // Genera el array 'firstRow' basándote en las claves del primer objeto de 'datos'
    /* if (this.datos.length > 0) {
      this.firstRow = this.datos[0].map((key, index) => ({
        key: index,
        label: key, 
        stickyColumn: true,
      }));
    } */
  },
  mounted(){
    let auxHeader = [];   
    if (this.datos.length > 0) {
      this.datos.map((key,index)=>{
        if(index ==0){
          auxHeader = key;
        
        }else {
          const obj1 = {};
          auxHeader.forEach((element,i) => {   
          
            obj1[element] = key[i];
            
          });
          this.info.push(obj1);
        }      
      })
    }
  },
  methods: {
    // Función para formatear una fecha
    formatearFecha(fecha) {  

      if (fecha == '' || fecha == null  ) {
        return ''; 
      }    

      if(typeof(fecha)=='string')  {       
        return fecha;
      }
   
      // Parsea la fecha con moment-timezone y ajusta a la zona horaria de Perú
      const fechaAjustada = moment.tz(fecha, 'America/Lima');

      // Agrega un día a la fecha ajustada
      const fechaCorregida = fechaAjustada.add(1, 'day');

      // Formatea la fecha como "DD/MM/YYYY"
      return fechaCorregida.format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">

.date-class {
    width: 100px;
  }
</style>
